.share-button--container {
  margin: 10px;
  cursor: pointer;
  opacity: 0;
  display: flex;
  transform: translateY(20px);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  animation: enter-from-bottom 0.3s 1.6s forwards;
}

.share-button--text {
  color: white;
  margin: 5px 0 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
