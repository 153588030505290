
.landing-container {
  display: flex;
  min-height: 100vh;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.banner {
  max-width: 480px;
}

.content {
  margin-top: 200px;
  text-align: center;
  justify-content: center;
  display: flex;
}

.deviceContainer {
  z-index: 1;
  width: 45%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  z-index: 2;
  width: 55%;
  margin-left: 50px;
}

.title {
  max-width: 520px;
  text-align: left;
  margin-right: auto;
}

.paragraph {
  max-width: 460px;
  text-align: right;
  margin-left: auto;
  font-size: large;
}

.device {
  position: absolute;
}

.background {
  /* Move it halfway back(x,y) */
}

@media only screen and (max-width: 800px) {
  .landing-container {
    padding: 10px;
  }
  .banner {
    max-width: 100%;
  }
  .content {
    margin-top: 0;
    flex-direction: column;
  }
  .deviceContainer {
    width: 100%;
    height: 50%;
  }
  .text {
    margin-left: 0;
    width: 100%;
    z-index: 2;
  }
  .paragraph {
    margin-top: 40px;
    width: 100%;
  }
  .background {
    max-width: 100%;
  }
  .device {
    max-width: 100%;
  }
}
